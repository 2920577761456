@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  /* #11150d,  #314026,  #526a40,  #739559,  #94bf73 and  #b5ea8c */
  --body-background-color: #fcfcfc;
  --menu-color: #314026;
  --menu-hover-color: #526a40;
  --menu-active-color: #11150d;
  --menu-background-color: #94bf73;
}

body {
  background-color: var(--body-background-color);
  font-family: 'Montserrat', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}