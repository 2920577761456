.home-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
  color: var(--menu-color);
  margin-top: 120px;
}

.content {
  max-width: 800px;
  margin: 0 auto;
}

.content img {
  width: 100px;
  margin-bottom: 1rem;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

p {
  font-size: 1.25rem;
  line-height: 1.5;
}