/* src/components/BlogSection.css */

.blog-section {
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.blog-post {
  background-color: #f4f4f4;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-post h2 {
  font-size: 1.75rem;
  margin-bottom: 10px;
  text-align: justify;
}

.blog-post .company {
  font-size: 1.1rem;
  color: #555;
  font-style: italic;
}

.blog-post .date {
  color: #888;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.blog-post p {
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
}

/* Video container styling */
.video-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.video-container iframe {
  width: 100%;
  max-width: 560px;
  height: 315px;
  border: none;
  border-radius: 8px;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
}

.loading p {
  font-size: 1.5rem;
  color: #555;
  margin-top: 10px;
}

.spinner {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid var(--menu-background-color);
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}