.App {
  text-align: center;
}

.App-header {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: var(--menu-background-color);
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 30px; /* Adjust as needed */
  margin-right: 10px;
}

.site-name {
  color: var(--menu-color);
  letter-spacing: -1px;
  font-weight: bold;
  font-size: 1.2rem;
}

nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  margin-left: 20px;
}

nav a {
  color: var(--menu-color);
  text-decoration: none;
  font-size: 1rem;
}

nav a:hover {
  color: var(--menu-hover-color);
  font-weight: bold;
}

nav a.active {
  color: var(--menu-active-color);
  font-weight: bold;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .App-header {
    flex-direction: column;
  }

  nav ul {
    margin-top: 10px;
  }
}