.resume-page {
  padding: 40px;
  text-align: center;
}

.resume-page h1 {
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #333;
}
